/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../src/theme/variables.scss";


.alert-red h2{
    color: #ff4757 !important;
}  

.alert-success h2{
    color: #009432 !important;
}  

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.2;
}

.hand{
  cursor: pointer;
}



.mat-form-field {
  height: 50px !important;

  .mat-form-field-flex {
    max-height: 50px !important;
  }
}
.mat-input-element[type="date"]::after,
.mat-input-element[type="datetime"]::after,
.mat-input-element[type="datetime-local"]::after,
.mat-input-element[type="month"]::after,
.mat-input-element[type="week"]::after,
.mat-input-element[type="time"]::after {
  content: none;
  white-space: pre;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0 0.8em 0;
}

